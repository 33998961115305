import React, { Suspense, lazy } from 'react';
import classes from './App.module.css';
import image from './assets/svgs/Group 15085.svg';

const Application = lazy(() => import('./routes/AppRoutes'));

function App() {
  return (
    <div className={`col-12 ${classes.body}`}>
      <Suspense
        fallback={
          <div className={`d-flex  flex-column justify-content-center align-items-center` } style={{height: `100vh`}}>
            <img src={image} className='img-fluid' />
          </div>
        }
      >
        <Application />
      </Suspense>
    </div>
  );
}

export default App;
